import { CSSProperties, useCallback, useEffect, useState } from "react";
import { DragHandlers, motion } from "framer-motion";

import switchImgUrl from "./switch.png";
import switchAudioUrl from "./switch-sound.mp3";
import "./App.css";
import "./Reset.css";

const GENERATIVE_ART_URL = "https://projects.alberto.omg.lol/generative-art/";
const GENUARY_URL = "https://projects.alberto.omg.lol/genuary24/";
const SWITCH_SOUND = new Audio(switchAudioUrl);
// Assume people only visit my website from planet Earth
const EARTH_GRAVITY = 9.8;
// Made up type, since there's not an official one for this experimental API?
type GravitySensor =
  | (Pick<Element, "addEventListener" | "removeEventListener"> &
      Gravity & {
        start: () => void;
        stop: () => void;
      })
  | null;
type Gravity = {
  x: number;
  y: number;
  z: number;
};

export default function App() {
  const [counters, setCounters] = useState({ switch: 0, avatar: 0 });
  const [randomColor, setRandomColor] = useState(getRandomColor());
  // Mobile only, uses gravity sensor data.
  const [gravity, setGravity] = useState<Gravity>({ x: 0, y: 0, z: 0 });

  const [experience, setExperience] = useState(getExperience());
  useEffect(() => {
    const interval = setInterval(() => setExperience(getExperience()), 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!("GravitySensor" in window)) {
      return;
    }

    let gravitySensor: GravitySensor = null;
    try {
      // @ts-expect-error as GravitySensor is still an experimental API
      gravitySensor = new window.GravitySensor({
        frequency: 60,
      }) as NonNullable<GravitySensor>;
      gravitySensor.addEventListener("reading", () => {
        const { x, y, z } = gravitySensor!;
        setGravity({ x, y, z });
      });
      gravitySensor.start();
    } catch (error) {
      // This is an experimental feature, let's not crash the website, shall we?
    }

    return () => gravitySensor?.stop();
  }, []);

  const [isLightOn, setIsLightOn] = useState(false);
  const [hasActivatedSwitch, setHasActivatedSwitch] = useState(false);
  const handleDrag: DragHandlers["onDrag"] = useCallback(
    (_e, { point: { y } }) => {
      if (hasActivatedSwitch) {
        return;
      }

      if (y > 225) {
        try {
          SWITCH_SOUND.play();
          setCounters((prev) => ({ ...prev, switch: prev.switch + 1 }));
        } catch (e) {
          if (e instanceof DOMException) {
            // Likely "play() failed because the user didn't interact with the document first".
            // No need to pollute the console with this error.
          }
        }
        setHasActivatedSwitch(true);
        setIsLightOn((prev) => !prev);
      }
    },
    [hasActivatedSwitch]
  );

  const handlePoke = useCallback(() => {
    setCounters((prev) => ({ ...prev, avatar: prev.avatar + 1 }));
  }, []);

  return (
    <>
      <nav>
        <div className="logo">Alberto Morabito</div>
        <div className="buttons-container">
          <a
            href={GENERATIVE_ART_URL}
            style={{ "--random-colour": randomColor } as CSSProperties}
            onMouseLeave={() => setRandomColor(getRandomColor())}
          >
            Generative Art
          </a>
          <a
            href={GENUARY_URL}
            style={{ "--random-colour": randomColor } as CSSProperties}
            onMouseLeave={() => setRandomColor(getRandomColor())}
          >
            Genuary &apos;24
          </a>
        </div>
      </nav>
      <div
        style={{
          filter: `brightness(${isLightOn ? 1 : 0.5})`,
          position: "relative",
        }}
      >
        <motion.img
          src={switchImgUrl}
          drag="y"
          whileTap={{ cursor: "grabbing" }}
          dragMomentum={false}
          dragElastic={0.1} // Make it hard to drag far away (and expose top of switch)
          dragConstraints={{ top: 0, bottom: 25 }}
          style={{
            position: "absolute",
            top: -125,
            left: 25,
            width: 100,
            rotate: `${scaleGravityToRange(gravity.x, -30, 30)}deg`,
            filter: !isLightOn ? "drop-shadow(black 0px 4px 6px)" : undefined,
          }}
          onDrag={handleDrag}
          onDragEnd={() => setHasActivatedSwitch(false)}
          onContextMenu={(e) => e.preventDefault()}
        ></motion.img>
        <div className="container">
          <Avatar onPoke={handlePoke} />
          <div className="intro-container">
            {`Hey, I'm Albert.
            Software Engineer with ${experience} of experience.
          I mostly create web products for a living.`}
            <br />
            <br />
            {counters.switch > 0 && (
              <p>
                You've flipped the switch {counters.switch} time
                {counters.switch !== 1 ? "s" : ""}
              </p>
            )}
            {counters.avatar > 0 && (
              <p>
                You've poked me {counters.avatar} time
                {counters.avatar !== 1 ? "s" : ""}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function Avatar({ onPoke }: { onPoke: () => void }) {
  return (
    <div className="avatar-container" onContextMenu={(e) => e.preventDefault()}>
      <motion.img
        draggable={false}
        className="avatar-img"
        src="https://avatars.githubusercontent.com/u/33761650?v=4"
        alt="A photo of Albert's face"
        whileHover={{
          rotate: 360,
        }}
        whileTap={{ rotate: 360 }}
        onHoverStart={onPoke}
        onTap={onPoke}
        transition={{
          type: "spring",
        }}
      />
    </div>
  );
}

function getRandomColor() {
  const randomHex = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomHex.length < 6 ? randomHex + "a" : randomHex}`;
}

function scaleGravityToRange(
  gravityValue: number,
  newMin: number,
  newMax: number
) {
  return (
    ((gravityValue - -EARTH_GRAVITY) * (newMax - newMin)) /
      (EARTH_GRAVITY - -EARTH_GRAVITY) +
    newMin
  );
}

const AVG_DAYS_IN_MONTH = 30.44;
function getExperience() {
  const startDate = new Date("2018-06-01T09:00:00Z").getTime();
  const currentDate = new Date().getTime();

  const seconds = Math.floor((currentDate - startDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / AVG_DAYS_IN_MONTH);
  const years = Math.floor(months / 12);

  const result = [`${years} years`];
  if (months % 12 !== 0) {
    result.push(`${months % 12} ${pluralise("month", months % 12)}`);
  }
  if (days % 30 !== 0) {
    result.push(`${days % 30} ${pluralise("day", days % 30)}`);
  }
  if (minutes % 60 !== 0) {
    result.push(`${minutes % 60} ${pluralise("minute", minutes % 60)}`);
  }
  if (seconds % 60 !== 0) {
    result.push(`${seconds % 60} ${pluralise("second", seconds % 60)}`);
  }
  return result.join(", ");
}

function pluralise(thing: string, quantity: number) {
  return thing + (quantity === 1 ? "" : "s");
}
